<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="add_category()"
                >
                  Dodaj
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-form v-model="valid">
          <v-card>
            <v-card-title>Dodawanie kategorii</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>

                  <v-text-field
                    v-model="name"
                    label="Nazwa"
                    color="primary"
                  ></v-text-field>

                  <v-textarea
                    v-model="description"
                    label="Opis kategorii"
                  ></v-textarea>

                  <!-- <v-select
                    v-model="selected_users"
                    :items="users"
                    item-text="name"
                    item-value="id"
                    label="Dostęp do kategorii:"
                    single-line
                    chips
                    multiple
                  ></v-select> -->

                  <v-autocomplete
                    v-model="selected_users"
                    :items="users"
                    item-text="name"
                    item-value="id"
                    no-data-text="Brak wyników"
                    label="Dostęp do kategorii:"
                    :rules="$store.state.rules.not_null"
                    chips
                    multiple
                  ></v-autocomplete>
                
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    id: 0,
    valid: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Baza wiedzy',
        disabled: false,
        to: '/baza-wiedzy-lista',
      },
      {
        text: 'Dodawanie kategorii',
        disabled: true,
        to: '/edit',
      }
    ],

    users: [],
    selected_users: [],
    name: '',
    description: '',
  }),
  methods: {
    add_category() {
      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("description", this.description);
      
      for(let index in this.selected_users){
        formData.append("user["+index+"]", this.selected_users[index]);
      }

      this.$axios({url: this.$store.state.api +'/faq_category', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$router.push('/baza-wiedzy-lista');
          this.$store.commit("snackbar", {
            text: "Dodano kategorie",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
  },
  mounted(){
    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/auth/user', data: {}, method: 'GET' })
      .then(resp => {
        console.log(resp.data);
        this.users = resp.data;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
</style>